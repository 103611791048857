import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Location } from '../entity/Location';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'ngx-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  @Input() text = '';
  @Output() positionChanged = new EventEmitter();

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  address = new FormControl();

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {
  }

  checkValue(value) {
    if (value.target.value.length === 0) {
      this.positionChanged.emit(null);
    }
  }

  ngOnInit() {
    this.address.setValidators(Validators.required);
    this.address.setValue(this.text);
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'],
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.address.setValue(place.formatted_address);
          this.positionChanged.emit(place);
        });
      });
    });
  }
}
