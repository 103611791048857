import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvestorService } from '../../../services/investor.service';

@Component({
  selector: 'app-modal-action-item',
  templateUrl: './addActionItemModal.component.html',
  styleUrls: ['./addActionItemModal.component.scss'],
})
export class AddActionItemModalComponent implements OnInit {

  @Input() id: string;
  addForm: FormGroup;

  constructor(
    protected ref: NbDialogRef<AddActionItemModalComponent>, 
    private fb: FormBuilder,
    private investorService: InvestorService,
    private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      link: [null]
    });
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.investorService.AddActionsItems(this.id, this.addForm.value.title, this.addForm.value.description, this.addForm.value.link).subscribe((data) => {
      if (data){
        this.toastrService.success('It was created succesfully.', 'Add Action Item');
      } else {
        this.toastrService.danger('There was an issue when trying to add a new action item.', 'Add Action Item');
      }
    });   
    this.ref.close(true);
  }
}
