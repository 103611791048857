import { Component, OnInit, Inject } from '@angular/core';
import { NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { takeWhile } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { get as _get } from 'lodash';

@Component({
  selector: 'ngx-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  private alive = true;
  subscription: any;
  authenticated = false;
  token = '';

  constructor(protected auth: NbAuthService) {
    this.subscription = auth
      .onAuthenticationChange()
      .pipe(takeWhile(() => this.alive))
      .subscribe((authenticated: boolean) => {
        this.authenticated = authenticated;
      });
  }

}

export class BasicAuthScreen implements OnInit {
  redirectDelay = 1000;
  showMessages: any = {};
  strategy = '';
  prefix = '';
  redirectUrl = '';
  errors: string[] = [];
  messages: string[] = [];
  submitted = false;
  params: any = {};

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected router: Router,
              protected route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.redirectDelay = this.getConfigValue(`${this.prefix}.redirectDelay`);
    this.showMessages = this.getConfigValue(`${this.prefix}.showMessages`);
    this.strategy = this.getConfigValue(`${this.prefix}.strategy`);
    this.route.queryParams.subscribe((params) => this.params = params);
  }

  setFormVars() {
    this.errors = this.messages = [];
    this.submitted = true;
  }

  getConfigValue(key: string): any {
    return _get(this.options, key, null);
  }

}
