import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';

@Component({
  selector: 'app-add-update-project',
  templateUrl: './add-update-project.component.html',
  styleUrls: ['./add-update-project.component.scss']
})
export class AddUpdateProjectComponent implements OnInit {
  @Input() project;
  @Input() options;
  updateForm: FormGroup;

  constructor(protected ref: NbDialogRef<AddUpdateProjectComponent>, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.updateForm = this.fb.group({
      description: [null, [Validators.required]],
      type_update: [null, [Validators.required]],
      other_type: null,
      date: [null, [Validators.required, CustomValidators.date]]
    });
  }

  checkOtherType() {
    return this.updateForm.get('type_update').value === 'Other';
  }

  getStatusValid(key) {
    return this.updateForm.get(key).dirty ||
      this.updateForm.get(key).touched ?
      (this.updateForm.get(key).invalid ? 'danger' : 'success') : 'basic';
  }

  getValidClass(key) {
    return this.updateForm.get(key).invalid &&
      this.updateForm.get(key).touched ? true : null;
  }

  cancel() {
    this.ref.close();
  }
  submit() {
    this.ref.close(this.updateForm.value);
  }

}
