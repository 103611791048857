import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Location } from '../entity/Location';

@Component({
  selector: 'ngx-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;
  @Input() initialize = false;
  @Input()
  public set searchedLocation(searchedLocation: Location) {
    if (searchedLocation) {
      this.latitude = searchedLocation.latitude;
      this.longitude = searchedLocation.longitude;
      this.zoom = 12;
    }
  }

  ngOnInit(): void {
    // set up current location
    if (this.initialize) {
      return;
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.searchedLocation = new Location(
          position.coords.latitude, position.coords.longitude,
        );
      });
    }
  }
}
