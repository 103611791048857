<nb-card>
    <nb-card-header class="text-center">{{project.name}} - New Document</nb-card-header>
    <nb-card-body>
        <form [formGroup]="documentForm">
            <div class="form-group row">
                <label for="inputEmail1" class="label col-sm-3 col-form-label">Title</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="title" nbInput fullWidth placeholder="Type title documentation">
                    <ng-container *ngIf="documentForm.get('title').invalid && documentForm.get('title').touched">
                        <small class="error">Title is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail1" class="label col-sm-3 col-form-label">Description</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="description" nbInput fullWidth
                        placeholder="Type description document">
                    <ng-container
                        *ngIf="documentForm.get('description').invalid && documentForm.get('description').touched">
                        <small class="error">Decription is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="from" class="label col-sm-3 col-form-label">Date</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth [nbDatepicker]="date" placeholder="Pickup a date"
                        formControlName="from" [attr.aria-invalid]="getValidClass('from')"
                        [status]="getStatusValid('from')">
                    <nb-datepicker [max]="max_date" #date></nb-datepicker>
                    <ng-container
                        *ngIf="documentForm.get('from').invalid && documentForm.get('from').touched">
                        <small class="error">Date is required.</small>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="form-group row">
                <label for="to" class="label col-sm-3 col-form-label">To Date</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth [nbDatepicker]="to" placeholder="Pickup a to date"
                        formControlName="to" [attr.aria-invalid]="getValidClass('to')"
                        [status]="getStatusValid('to')">
                    <nb-datepicker #to></nb-datepicker>
                    <ng-container *ngIf="documentForm.get('to').invalid && documentForm.get('to').touched">
                        <small class="error">From Date is required.</small>
                    </ng-container>
                </div>
            </div> -->
            <div class="form-group row">
                <label class="col-sm-3 label">Type Document</label>
                <div class="col-sm-9">
                    <nb-radio-group formControlName="type_document">
                        <nb-radio *ngFor="let option of options" [value]="option.name">
                            {{option.name}}
                        </nb-radio>
                    </nb-radio-group>
                </div>
            </div>
            <ng-container *ngIf="checkOtherType()">
                <div class="form-group row">
                    <label for="inputEmail1" class="label col-sm-3 col-form-label">Other Type</label>
                    <div class="col-sm-9">
                        <input type="text" formControlName="other_type" nbInput fullWidth placeholder="Type update">
                        <ng-container
                            *ngIf="documentForm.get('other_type').invalid && documentForm.get('other_type').touched">
                            <small class="error">Other Type is required.</small>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div class="form-group row">
                <div class="offset-sm-3">
                    <app-file-upload formControlName="pdf" [disabled]="false" [type]="'file'"
                        (upload)="handleUploadFile($event)"></app-file-upload>
                </div>
            </div>
            <div class="form-group row">
                <div class="offset-sm-4 col-sm-8">
                    <button (click)="submit()" status="primary" [disabled]="documentForm.invalid"
                        nbButton>Submit</button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>