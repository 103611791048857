import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class UserService {
  constructor(private httpClient: HttpClient) {}
  getMe() {
    return this.httpClient.get<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/me`
    );
  }

  getUserDocumentation(userId) {
    return this.httpClient.get<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${userId}/documentations`
    );
  }

  createNewUser(payload) {
    return this.httpClient.post<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users`,
      payload
    );
  }

  savePersonalInformation(id, payload) {
    return this.httpClient.post<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}/personal-information`,
      payload
    );
  }

  updatePersonalInformation(id, payload) {
    return this.httpClient.patch<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}/personal-information`,
      payload
    );
  }

  completeOnboarding(id, payload) {
    return this.httpClient.patch<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}/finally-onboarding`,
      payload
    );
  }

  addImageDocument(userId, file, type) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type_file", type);
    const url = `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${userId}/files-onboarding`;
    return this.httpClient.patch(url, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  verifyUser(username) {
    return this.httpClient.post<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/verify`,
      { username }
    );
  }

  deleteInvestor(id) {
    return this.httpClient.delete<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}`
    );
  }

  deleteInvestorAccount(id: string) {
    return this.httpClient.delete(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/me/investorsacc/del/${id}`
    );
  }

  // Find the list of investors
  findAll(query) {
    return this.httpClient.get<any>(
      `${environment.HOST_API}${
        environment.BASE_END_POINT_URL
      }/users?${new URLSearchParams(query)}`
    );
  }

  // Find the investor account or the natural personal account ????
  getInvestor(id) {
    return this.httpClient.get<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}`
    );
  }

  // Find the list (array) of investors entities
  getInvestorEntities(id) {
    return this.httpClient.get<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/me/investoracc/${id}`
    );
  }

  resetPassword(id, payload) {
    return this.httpClient.patch<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}/reset-password`,
      payload
    );
  }

  editInvestorAccount(id, payload) {
    return this.httpClient.patch<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/me/${id}/investor-information`,
      payload
    );
  }

  createIndividualAccount(id, data, type_files) {
    const formData = new FormData();
    for (let i = 0; i < data.files.length; i++) {
      formData.append('file', data.files[i]);
    }
    type_files.forEach((tag) => formData.append('type_files[]', tag));
    formData.append('data', JSON.stringify(data));

    return this.httpClient.post<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}/setinvestoracc`,
      formData
    );
  }

  createEntityAccount(id, data, type_files) {
    const formData = new FormData();
    for (let i = 0; i < data.files.length; i++) {
      formData.append('file', data.files[i]);
    }
    type_files.forEach((tag) => formData.append('type_files[]', tag));
    formData.append('data', JSON.stringify(data));

    return this.httpClient.post<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id}/setinvestoracc`,
      formData
    );
  }

  reassignToAccount(investmentId, accountId){
    return this.httpClient.patch<any>(
      `${environment.HOST_API}${environment.BASE_END_POINT_URL}/investments/${investmentId}/account/${accountId}`,
      {}
    );
  }
}
