<nb-card>
    <nb-card-header class="text-center">{{title}}</nb-card-header>
    <nb-card-body>
        <form [formGroup]="operationForm">
            <div class="form-group row">
                <label for="inputEmail1" class="label col-sm-3 col-form-label">Title</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="title" nbInput fullWidth id="text"
                        placeholder="Type title">
                    <ng-container *ngIf="operationForm.get('title').invalid && operationForm.get('title').touched">
                        <small class="error">Title is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail1" class="label col-sm-3 col-form-label">Description</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="description" nbInput fullWidth id="text"
                        placeholder="Type description">
                </div>
            </div>
            <div class="form-group row">
                <label for="date" class="label col-sm-3 col-form-label">Date</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth [nbDatepicker]="date" placeholder="Pickup a date"
                        formControlName="date" [attr.aria-invalid]="getValidClass('date')"
                        [status]="getStatusValid('date')">
                    <nb-datepicker #date></nb-datepicker>
                    <ng-container *ngIf="operationForm.get('date').invalid && operationForm.get('date').touched">
                        <small class="error">Date is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="name" class="label col-sm-3 col-form-label">Amount</label>
                <div class="col-sm-9">
                    <input [attr.aria-invalid]="validation.getValidClass(operationForm, 'amount')"
                        [status]="validation.getStatusValid(operationForm, 'amount')" nbInput
                        (blur)="validation.transformTotal(operationForm, 'amount')" id="amount"
                        formControlName="amount" placeholder="Type a number value">
                    <ng-container
                        *ngIf="operationForm.get('amount').touched && operationForm.get('amount').errors?.required">
                        <small class="error d-block">Investment amount should be valid.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <div class="offset-3 col-4">
                    <button (click)="cancel()" status="danger" nbButton>Cancel</button>
                </div>
                <div class="col-4">
                    <button (click)="submit()" status="success" [disabled]="operationForm.invalid"
                        nbButton>Submit</button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>