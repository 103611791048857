<nb-card>
    <nb-card-header class="text-center">{{project.name}} - New update</nb-card-header>
    <nb-card-body>
        <form [formGroup]="updateForm">
            <div class="form-group row">
                <label for="inputEmail1" class="label col-sm-3 col-form-label">Description</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="description" nbInput fullWidth id="text"
                        placeholder="Type description update">
                    <ng-container
                        *ngIf="updateForm.get('description').invalid && updateForm.get('description').touched">
                        <small class="error">Decription is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="date" class="label col-sm-3 col-form-label">Date</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth [nbDatepicker]="date" placeholder="Pickup a date" formControlName="date"
                        [attr.aria-invalid]="getValidClass('date')" [status]="getStatusValid('date')">
                    <nb-datepicker #date></nb-datepicker>
                    <ng-container *ngIf="updateForm.get('date').invalid && updateForm.get('date').touched">
                        <small class="error">Date is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 label">Type</label>
                <div class="col-sm-9">
                    <nb-radio-group formControlName="type_update">
                        <nb-radio *ngFor="let option of options" [value]="option.name">
                            {{option.name}}
                        </nb-radio>
                    </nb-radio-group>
                </div>
            </div>
            <ng-container *ngIf="checkOtherType()">
                <div class="form-group row">
                    <label for="inputEmail1" class="label col-sm-3 col-form-label">Other Type</label>
                    <div class="col-sm-9">
                        <input type="text" formControlName="other_type" nbInput fullWidth id="text"
                            placeholder="Type update">
                        <ng-container
                            *ngIf="updateForm.get('other_type').invalid && updateForm.get('other_type').touched">
                            <small class="error">Other Type is required.</small>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div class="form-group row">
                <div class="offset-sm-4 col-sm-8">
                    <button (click)="submit()" status="primary" [disabled]="updateForm.invalid" nbButton>Submit</button>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
    </nb-card-footer>
</nb-card>