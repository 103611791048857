import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { CustomValidators } from 'ngx-custom-validators';
import { ValidationFormService } from 'src/app/@core/service/validation-form.service';
import numeral from 'numeral';

@Component({
  selector: 'app-add-operation-investment',
  templateUrl: './add-operation-investment.component.html',
  styleUrls: ['./add-operation-investment.component.scss']
})
export class AddOperationInvestmentComponent implements OnInit {

  @Input() investment;
  @Input() title;
  @Input() data;
  operationForm: FormGroup;
  constructor(
    protected ref: NbDialogRef<AddOperationInvestmentComponent>,
    public validation: ValidationFormService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.operationForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null],
      date: [null, [Validators.required, CustomValidators.date]],
      amount: [null, [Validators.required]]
    });
    if (this.data) {
      this.operationForm.patchValue(this.data);
    }
  }

  getStatusValid(key) {
    return this.operationForm.get(key).dirty ||
      this.operationForm.get(key).touched ?
      (this.operationForm.get(key).invalid ? 'danger' : 'success') : 'basic';
  }

  getValidClass(key) {
    return this.operationForm.get(key).invalid &&
      this.operationForm.get(key).touched ? true : null;
  }

  cancel() {
    this.ref.close();
  }
  submit() {
    const payload = Object.assign({}, this.operationForm.value, { amount: numeral(this.operationForm.value.amount).value() });
    this.ref.close(payload);
  }

}
