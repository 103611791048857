import { Component, OnInit, Inject } from '@angular/core';
import { NbAuthService, NB_AUTH_OPTIONS, NbAuthResult, NbTokenStorage } from '@nebular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { BasicAuthScreen } from '../auth.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BasicAuthScreen implements OnInit {

  user: any = {
    username: '',
    password: '',
  };
  submitted = false;
  prefix = '';
  errors: string[] = [];
  messages: string[] = [];

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected router: Router,
              private tokenStorage: NbTokenStorage,
              protected route: ActivatedRoute) {
    super(service, options, router, route);
  }

  ngOnInit() {
    if (localStorage.getItem('auth_app_token')) {
      this.router.navigate([`${environment.ROOT_PAGE}`]);
    }
    super.ngOnInit();
  }

  login() {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.service.authenticate('username', this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;
      
      if (result.isSuccess()) {
        this.messages = result.getMessages();

      } else {
        this.errors = result.getErrors();
      }
      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          this.router.navigate([`${environment.ROOT_PAGE}`]);
        });
      }
    }, error => {
    });

  }

}
