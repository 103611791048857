import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthComponent } from './shared/components/auth/auth.component';
import { LoginComponent } from './shared/components/auth/login/login.component';
import { NbLogoutComponent } from '@nebular/auth';
import { AuthGuardService } from './@core/utils/auth-guard.service';

const routes: Routes = [
  {
    path: 'privacy-policy',
    loadChildren: () => import('./term-conditions/term-conditions.module')
      .then(m => m.TermConditionsModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule),
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
    ],
  },
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  { path: '**', redirectTo: 'admin', pathMatch: 'full' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
