import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';

@Component({
  selector: 'app-document-add',
  templateUrl: './document-add.component.html',
  styleUrls: ['./document-add.component.scss']
})
export class DocumentAddComponent implements OnInit {

  @Input() investment;
  documentForm: FormGroup;
  options = [{ name: 'Operating Agreement' }, { name: 'Purchasing Agreement' },  { name: 'Other' }];
  file;
  constructor(protected ref: NbDialogRef<DocumentAddComponent>, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.documentForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      type_document: ['Closing Documents', [Validators.required]],
      other_type: [],
      date: [null, [Validators.required, CustomValidators.date]],
      pdf: [null, [Validators.required]]
    });
  }

  getStatusValid(key) {
    return this.documentForm.get(key).dirty ||
      this.documentForm.get(key).touched ?
      (this.documentForm.get(key).invalid ? 'danger' : 'success') : 'basic';
  }

  getValidClass(key) {
    return this.documentForm.get(key).invalid &&
      this.documentForm.get(key).touched ? true : null;
  }

  handleUploadFile(files) {
    this.file = files[0];
    this.documentForm.get('pdf').setValue(this.file);
  }

  checkOtherType() {
    return this.documentForm.get('type_document').value === 'Other';
  }

  cancel() {
    this.ref.close();
  }
  submit() {
    this.ref.close(this.documentForm.value);
  }

  get title() {
    if (!this.investment) {
      return;
    }
    if (!this.investment.user.personal_information) {
      return;
    }
    const { user: { personal_information: info } } = this.investment;
    return `${info.first_name} ${info.last_name} - ${info.type_investor}`;
  }
}
