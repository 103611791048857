import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-files-gallery',
  templateUrl: './files-gallery.component.html',
  styleUrls: ['./files-gallery.component.scss']
})
export class FilesGalleryComponent implements OnInit {
  @Input() files = [];
  @Input() conf = {};
  @Output() deleteItem: EventEmitter<any>  = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  removeLink(item) {
    this.deleteItem.emit(item);
  }

  openWindow(url) {
    window.open(url, '_blank');
  }
}
