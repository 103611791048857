import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  fileData: File = null;
  @Input() disabled = true;
  @Input() previewUrl: any = null;
  @Input() type = 'image';
  @Output() upload = new EventEmitter();
  @Input() fileUploadProgress: string = null;
  @Input() showPreview = false;
  uploadedFilePath: string = null;
  title = 'Photos';
  file_type = 'image/*';
  ngOnInit() {
    if (this.type === 'file') {
      this.title = 'Attachments';
      this.file_type = '.doc,.pdf,.docx,.xls,.xlsx';
    }
  }

  fileProgress(fileInput: any) {
      this.fileData = fileInput.target.files;
      if (this.showPreview) {
        this.preview();
      }
      this.upload.emit(this.fileData);
  }

  preview() {
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData[0]);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

}
