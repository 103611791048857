<div class="container">
    <div class="row">
        <div class="col-12 col-sm-6 offset-sm-5">
            <div class="mb-3" *ngIf="previewUrl">
                <img class="image-preview" [src]="previewUrl" alt="picture"/>                 
            </div>
            <div class="form-group">
                <input style="display: none" [accept]="file_type" type="file" name="image" (change)="fileProgress($event)" #fileInput multiple/>
                <button [disabled]="disabled" nbButton status="info" size="medium" (click)="fileInput.click()">Upload</button>
            </div>
            <div *ngIf="fileUploadProgress">
                Upload progress: {{ fileUploadProgress }}
            </div>
 
            <div class="mb-3" *ngIf="uploadedFilePath">
                {{uploadedFilePath}}
            </div>
        </div>
    </div>
</div>