<header class="mb-2">
    <div class="text-center">
      <img class="logo-img" src="/assets/logos/logo.png" alt="">
    </div>
  </header>
  
  <form (ngSubmit)="login()" #form="ngForm">
  
    <div *ngIf="errors && errors.length > 0 && !submitted" class="alert alert-danger" role="alert">
      <div>
        <strong>Oh Snap!</strong>
      </div>
      <small *ngFor="let error of errors">{{ error }}</small>
    </div>
  
    <div class="form-group">
      <label class="label" for="input-email"><strong>Email address:</strong></label>
      <input nbInput fullWidth class="form-control" [(ngModel)]="user.username" #email="ngModel" name="username" id="input-email"
        pattern=".+@.+\..+" placeholder="Email address" autofocus [status]="email.dirty || email.touched ? (email.invalid  ? 'danger' : 'success') : ''"
        [required]="getConfigValue('forms.validation.email.required')" [attr.aria-invalid]="email.invalid && email.touched ? true : null">
      <ng-container *ngIf="email.invalid && email.touched">
        <p class="form-text error text-center text-md-left" *ngIf="email.errors?.required">
          Email is required!
        </p>
        <p class="form-text error text-center text-lg-left" *ngIf="email.errors?.pattern">
          Email should be the real one!
        </p>
      </ng-container>
    </div>
  
    <div class="form-group">
      <label class="label" for="input-password"><strong>Password:</strong></label>
      <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password" id="input-password"
        placeholder="Password" class="form-control" [status]="password.dirty || password.touched ? (password.invalid  ? 'danger' : 'success') : ''"
        [attr.aria-invalid]="password.invalid && password.touched ? true : null" [class.form-control-danger]="password.invalid && password.touched"
        [required]="getConfigValue('forms.validation.password.required')" [minlength]="getConfigValue('forms.validation.password.minLength')"
        [maxlength]="getConfigValue('forms.validation.password.maxLength')">
      <ng-container *ngIf="password.invalid && password.touched ">
        <p class="form-text error text-center text-lg-left" *ngIf="password.errors?.required">
          Password is required!
        </p>
        <p class="error-message" *ngIf="password.errors?.minlength || password.errors?.maxlength">
          Password should contains
          from {{ getConfigValue('forms.validation.password.minLength') }}
          to {{ getConfigValue('forms.validation.password.maxLength') }}
          characters
        </p>
      </ng-container>
    </div>
  
    <button class="mt-3" nbButton fullWidth status="primary" [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted">
      Log In
    </button>
  </form>