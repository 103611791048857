import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnChanges {

  @Input() options;
  @Input() clean = false;
  @Input() placeholder;
  @Output() selected = new EventEmitter();
  filteredOptions$: Observable<any>;
  @ViewChild('autoInput') input;
  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.input) {
      this.input.nativeElement.value = '';
    }
  }

  ngOnInit() {
    this.filteredOptions$ = of(this.options);
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  onSelectionChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event.name);
    this.selected.emit($event);
  }

  viewHandle(value) {
    if (typeof value === 'string') {
      return value;
    }
    return value.name;
  }

}
