import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbSearchService } from '@nebular/theme';

@Component({
  selector: 'app-search-floating',
  templateUrl: './search-floating.component.html',
  styleUrls: ['./search-floating.component.scss']
})
export class SearchFloatingComponent implements OnInit {
  @Input() tag = 'global';
  @Input() hint = 'Enter para buscar';
  @Input() placeholder = 'Search';
  @Output() search: EventEmitter<any> = new EventEmitter();
  constructor(private searchService: NbSearchService) { }

  ngOnInit() {
    this.searchService.onSearchInput()
    .subscribe((data: any) => {
      this.search.emit({search: data.term});
    });
    this.searchService.onSearchActivate()
    .subscribe((data: any) => {
      this.search.emit({});
    });

  }

}
