import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbMenuModule, NbSidebarService, NbMenuService } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { CoreModule } from './@core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AuthGuardService } from './@core/utils/auth-guard.service';
import { ModalComponent } from './shared/components';
import { AddActionItemModalComponent } from './admin/investors/components/investors-action-items/add-action-item-modal/addActionItemModal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddUpdateProjectComponent } from './admin/projects/components/add-update-project/add-update-project.component';
import { DocumentAddComponent } from './admin/investment/components/investment-document/document-add/document-add.component';
import { ProjectDocAddComponent } from './admin/projects/components/project-documents/project-doc-add/project-doc-add.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AddOperationInvestmentComponent } from './shared/components/add-operation-investment/add-operation-investment.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    FontAwesomeModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbMenuModule.forRoot(),
    SharedModule.forRoot(),
    NgbModule,
],
  providers: [NbSidebarService, NbMenuService, AuthGuardService],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent,
    AddActionItemModalComponent,
    AddUpdateProjectComponent,
    DocumentAddComponent,
    ProjectDocAddComponent,
    AddOperationInvestmentComponent
  ]
})
export class AppModule { }
