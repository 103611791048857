import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { NbTokenStorage } from '@nebular/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenStorage: NbTokenStorage, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // internal endpoint to our API
    if (this.isBaasRequest(request)) {
      const headers = {
        setHeaders: {
          authorization: '',
        },
      };

      const token = this.tokenStorage.get().getValue();
      if (token && this.requiresAuthentication(request)) {
        headers.setHeaders.authorization = `bearer ${token}`;
      }

      request = request.clone(headers);
    }
    return next.handle(request).pipe(tap(event => { }, err => {
      if (this.isUnauthorizedError(err)) {
        this.tokenStorage.clear();
        // redirect to the login route
        this.router.navigate(['/auth/login']);
      }
    }));
  }

  private isUnauthorizedError(err: any) {
    return err instanceof HttpErrorResponse && err.status === 401;
  }

  private requiresAuthentication(request: HttpRequest<any>) {
    return !request.url.includes('/login');
  }
  private isBaasRequest(request: HttpRequest<any>) {
    return request.url.startsWith(`${environment.HOST_API}${environment.BASE_END_POINT_URL}/`);
  }
}
