import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {

  @Input() items = [];
  @Output() deleteImg = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  delete(item) {
    this.deleteImg.emit(item);
  }
}
