import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { NbDateService, NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-project-doc-add',
  templateUrl: './project-doc-add.component.html',
  styleUrls: ['./project-doc-add.component.scss']
})
export class ProjectDocAddComponent implements OnInit {

  @Input() project;
  documentForm: FormGroup;
  options = [{ name: 'Closing Documents' }, { name: 'Newsletters' }, { name: 'Other' }];
  file;
  max_date: Date = new Date();
  constructor(protected ref: NbDialogRef<ProjectDocAddComponent>, private fb: FormBuilder, protected dateService: NbDateService<Date>) {

  }

  ngOnInit(): void {

    this.max_date = this.dateService.today();

    this.documentForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      type_document: ['Closing Documents', [Validators.required]],
      other_type: [],
      from: [new Date(), [Validators.required, CustomValidators.date]],
      // to: [null, [Validators.required, CustomValidators.date]],
      pdf: [null, [Validators.required]]
    });
  }

  getStatusValid(key) {
    return this.documentForm.get(key).dirty ||
      this.documentForm.get(key).touched ?
      (this.documentForm.get(key).invalid ? 'danger' : 'success') : 'basic';
  }

  getValidClass(key) {
    return this.documentForm.get(key).invalid &&
      this.documentForm.get(key).touched ? true : null;
  }

  handleUploadFile(files) {
    this.file = files[0];
    this.documentForm.get('pdf').setValue(this.file);
  }

  cancel() {
    this.ref.close();
  }
  submit() {
    this.ref.close(this.documentForm.value);
  }

  checkOtherType() {
    return this.documentForm.get('type_document').value === 'Other';
  }

  get title() {
    if (!this.project) {
      return;
    }
    const { user: { personal_information: info } } = this.project;
    return `${info.first_name} ${info.last_name} - ${info.type_investor}`;
  }

}
