import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from './entity/Location';

@Component({
  selector: 'ngx-search-map',
  templateUrl: './search-map.component.html',
})
export class SearchMapComponent {
  searchedLocation: Location;
  @Input() address = null;
  @Input()
  public set location(address) {
    if (!address) {
      return;
    }
    if (address.location) {
      this.searchedLocation = new Location(address.location.coordinates[1], address.location.coordinates[0]);
    }
    if (address.street && address.street_number) {
      this.address = `${address.street} ${address.street_number} ${address.locality} ${address.province}`;
    } else {
      this.address = address;
    }
  }

  public get shouldInit() {
    return this.address ? true : false;
  }

  @Output() selectedLocation = new EventEmitter();


  updateLocation(address) {
    const {geometry: {location}} = address;
    this.searchedLocation = new Location(location.lat(), location.lng());
    this.selectedLocation.emit(address);
  }

}
