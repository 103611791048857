import { Component, Input, OnInit } from '@angular/core';
import { ProjectTypesService } from 'src/app/admin/projects/services/project-types.service';

@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss']
})
export class ProjectHeaderComponent implements OnInit {
  @Input() project;
  constructor(private projectTypesService: ProjectTypesService) { }

  ngOnInit() {
  }

  getType() {
    const projectType = this.projectTypesService.findProyectByKey(this.project.type);
    if (projectType) {
      return projectType.name;
    }
    return this.project.type;
  }

  getAddress() {
    try {
      const { address: { locality, province, street, street_number } } = this.project;
      return `${locality} ${street} ${street_number}, ${province}`;
    } catch {
      return '';
    }

  }

}
