import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent, SampleLayoutComponent } from './layout';
import {
  NbLayoutModule, NbThemeModule, NbMenuModule, NbUserModule, NbActionsModule, NbSearchModule,
  NbSidebarModule, NbContextMenuModule, NbButtonModule, NbSelectModule, NbIconModule,
  NbCardModule, NbTabsetModule, NbRouteTabsetModule, NbCheckboxModule, NbPopoverModule,
  NbProgressBarModule, NbCalendarModule, NbCalendarRangeModule, NbStepperModule, NbListModule,
  NbToastrModule, NbInputModule, NbAccordionModule, NbDatepickerModule, NbDialogModule,
  NbWindowModule, NbAlertModule, NbSpinnerModule, NbRadioModule, NbTooltipModule, NbToggleModule,
  NbFormFieldModule, NbAutocompleteModule, NbToastrConfig, NbGlobalPhysicalPosition
} from '@nebular/theme';
import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
  SearchTextComponent,
  SearchMapComponent,
  MapComponent,
  SearchComponent,
  ImageGalleryComponent,
  InvestmentDatatableComponent,
  ProjectHeaderComponent,
  AddOperationInvestmentComponent
} from './components';
import { NbSecurityModule } from '@nebular/security';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthComponent } from './components/auth/auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { ModalComponent } from './components/modal/modal.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SearchFloatingComponent } from './components/search-floating/search-floating.component';
import { AgmCoreModule } from '@agm/core';
import { FilesGalleryComponent } from './components/files-gallery/files-gallery.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, NgxDatatableModule];

const NB_MODULES = [
  NbCardModule,
  NbFormFieldModule,
  NbEvaIconsModule,
  NbLayoutModule,
  NbTabsetModule,
  NbAutocompleteModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbIconModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
  NbThemeModule,
  NbToggleModule
];

const COMPONENTS = [
  SampleLayoutComponent,
  FilesGalleryComponent,
  HeaderComponent,
  SearchFloatingComponent,
  FloatingButtonComponent,
  ModalComponent,
  FooterComponent,
  FileUploadComponent,
  AuthComponent,
  TinyMCEComponent,
  SearchInputComponent,
  LoginComponent, OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent,
  SearchTextComponent,
  SearchMapComponent,
  MapComponent,
  SearchComponent,
  ImageGalleryComponent,
  AutocompleteComponent,
  InvestmentDatatableComponent,
  ProjectHeaderComponent,
  AddOperationInvestmentComponent
];

const config: Partial<NbToastrConfig> = {
  position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
  duration: 5000,
  destroyByClick: true,
  preventDuplicates: true,
  hasIcon: true
};

const NB_THEME_PROVIDERS = [
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot(config).providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, AgmCoreModule.forRoot({
    apiKey: 'AIzaSyACu1RxkdqozA35hF1vq5UEx2raxttrjwM',
    libraries: ['places']
  }), ],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS],
  declarations: [...COMPONENTS],
  entryComponents: [ModalComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...NB_THEME_PROVIDERS],
    } as ModuleWithProviders;
  }
}
