import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbToastrService, NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Injectable } from '@angular/core';
import * as lodash from 'lodash';
import { NbTokenStorage } from '@nebular/auth';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastrService: NbToastrService, private token: NbTokenStorage, private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('login')) {
      return next.handle(request);
    }
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ups something is wrong';
          try {
            if (error) {
              if (error.status === 401) {
                errorMessage = error.error.info;
                this.token.clear();
                this.router.navigate(['/auth/login']);
              } else if (lodash.get(error, 'error.errors')) {
                const errors = lodash.get(error, 'error.errors');
                errorMessage = errors[0].message ? errors[0].message : errors[0];
              } else if (lodash.get(error, 'error.info.message')) {
                errorMessage = lodash.get(error, 'error.info.message');
              } else if (lodash.get(error, 'error.message')) {
                errorMessage = lodash.get(error, 'error.message');
              }
            }
          } catch (err) {}
          this.toastrService.danger(errorMessage, 'Error', {position: NbGlobalPhysicalPosition.BOTTOM_LEFT});
          return throwError(errorMessage);
        }),
      );
  }
}
