import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ngx-search-text',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchTextComponent implements OnInit {
  @Input() placeholder = '';
  @Input() tag = '';
  @Output() search: EventEmitter<any> = new EventEmitter();
  text: string;
  constructor() {}
  ngOnInit() {

  }

  searching() {
    if (this.text.length === 0) {
      this.text = '';
    }
    this.search.emit({search: this.text.trim()});
  }
}
