<nb-card>
    <nb-card-header>Add new action item</nb-card-header>
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class="form-group row">
                <label for="title" class="label col-sm-3 col-form-label">Title</label>
                <div class="col-sm-9">
                    <input type="text" required formControlName="title" nbInput fullWidth placeholder="Title">
                    <ng-container *ngIf="addForm.get('title').invalid && addForm.get('title').touched">
                        <small class="error">Title is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="description" class="label col-sm-3 col-form-label">Description</label>
                <div class="col-sm-9">
                    <input type="text" required [maxlength]="250" formControlName="description" nbInput fullWidth placeholder="Description">
                    <ng-container *ngIf="addForm.get('description').invalid && addForm.get('description').touched">
                        <small class="error">Description is required.</small>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="link" class="label col-sm-3 col-form-label">Link</label>
                <div class="col-sm-9">
                    <input type="text" [maxlength]="250" formControlName="link" nbInput fullWidth placeholder="http://www.google.com">
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton class="left" status="danger" (click)="cancel()">Cancel</button>
        <button nbButton class="right" status="success" [disabled]="addForm.invalid" (click)="submit()">Submit</button>
    </nb-card-footer>
</nb-card>