// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,

  /* IMPORTANT TO SET API ENVIRONMENT */
  HOST_API: 'https://api-dev.one-bric.link',  // Develop
  //  HOST_API: 'https://backend.onebric.com',  // Production

  // General
  BASE_END_POINT_URL: '/api/v1',
  // Security
  API_AUTH_LOGIN: '/api/auth/login',
  // Routes
  ROOT_PAGE: '/admin/projects',
  PAGE_AUTH_LOGIN: 'auth/login',
  // Users
  USER: '/users',
  IMAGES: '/images',
  ME: '/me',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
