import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import numeral from 'numeral';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class ValidationFormService {

    constructor(private currencyPipe: CurrencyPipe) {
    }

    public getStatusValid(formGroup: FormGroup, key) {
        return formGroup.get(key).dirty ||
            formGroup.get(key).touched ?
            (formGroup.get(key).invalid ? 'danger' : 'success') : 'basic';
    }

   public getValidClass(formGroup: FormGroup, key) {
        return formGroup.get(key).invalid &&
            formGroup.get(key).touched ? true : null;
    }

    public formatMoney(value) {
        let temp = `${value}`.replace(/\,/g, '');
        if (numeral(temp).value() === null) {
          temp = '0.00';
        }
        return this.currencyPipe.transform(temp).replace('$', '');
      }


    public transformTotal(formGroup: FormGroup, key) {
        const value = formGroup.controls[key].value;
        formGroup.get(key).setValue(
          this.formatMoney(value),
          {emitEvent: false}
        );
    }
}
