import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InvestorsModule } from '../investors.module';

@Injectable({
    providedIn: InvestorsModule,
})

export class InvestorService {

    constructor(private httpClient: HttpClient) { }

    updateFileState(payload: any, id_user: string) {
        return this.httpClient.patch(
            `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id_user}/files-onboardindg/change-status`,
            payload);
    }

    updateFileStateForEntities(payload: any, id_user: string) {
        return this.httpClient.patch(
            `${environment.HOST_API}${environment.BASE_END_POINT_URL}/users/${id_user}/files/change-status`,
            payload);
    }

    // get all action items
    getAllActionsItems(id: string) {
        return this.httpClient.get(
            `${environment.HOST_API}${environment.BASE_END_POINT_URL}/actionItems/${id}`);
    }

    // add action item
    AddActionsItems(userId: string, title: string, body: string, link: string) {
        return this.httpClient.post(
            `${environment.HOST_API}${environment.BASE_END_POINT_URL}/pendingItem`,
            {userId, title, body, link});
    }

    // delete action item
    deleteActionsItem(id: string) {
        return this.httpClient.delete(
            `${environment.HOST_API}${environment.BASE_END_POINT_URL}/pendingItem/${id}`);
    }

}
