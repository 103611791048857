import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { SortType } from '@swimlane/ngx-datatable';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-investment-datatable',
  templateUrl: './investment-datatable.component.html',
  styleUrls: ['./investment-datatable.component.scss']
})
export class InvestmentDatatableComponent implements OnInit {

  @Input() rows;
  @Input() showActions = false;
  @Output() deleteAction = new EventEmitter();
  @Output() updateAction = new EventEmitter();
  SortType = SortType;
  constructor(private dialogService: NbDialogService) { }

  ngOnInit() {
  }

  deleteRow(id) {
    this.dialogService.open(ModalComponent, {
      closeOnEsc: true,
      autoFocus: true,
      context: {
        title: 'Delete',
        message: `Are you sure you want to delete?`,
      },
    }).onClose.subscribe(result => {
      if (result) {
        this.deleteAction.emit(id);
      }
    });
  }

  update(update) {
    this.updateAction.emit(update);
  }

}
