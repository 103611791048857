import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestorListComponent } from './components/investor-list/investor-list.component';
import { InvestorDetailComponent } from './components/investor-detail/investor-detail.component';
import { InvestorHomeComponent } from './investor-home/investor-home.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { SharedModule } from 'src/app/shared/shared.module';
import { InvestorsRoutingModule } from './investors-routing.module';
import { InvestorUserComponent } from './components/investor-user/investor-user.component';
import { InvestorIndividualInfoComponent } from './components/investor-individual-info/investor-individual-info.component';
import { InvestorEntityInfoComponent } from './components/investor-entity-info/investor-entity-info.component';
import { InvestorAddComponent } from './components/investor-add/investor-add.component';
import { InvestorsActionsItemsComponent } from './components/investors-action-items/investors-action-items.component';
import { AddActionItemModalComponent } from './components/investors-action-items/add-action-item-modal/addActionItemModal.component';
import { InvestorEditEntityAccountComponent } from './components/investor-edit-entity-account/investor-edit-entity-account.component';
import { InvestorInfoComponent } from './components/investor-info/investor-info.component';
import { InvestorAddEntityAccountComponent } from './components/investor-add-entity-account/investor-add-entity-account.component';

@NgModule({
  declarations: [InvestorIndividualInfoComponent, InvestorEntityInfoComponent, InvestorListComponent,
    InvestorDetailComponent, InvestorHomeComponent,
    InvestorUserComponent, InvestorAddComponent,
    InvestorsActionsItemsComponent, AddActionItemModalComponent, InvestorEditEntityAccountComponent, InvestorInfoComponent, InvestorAddEntityAccountComponent],
  imports: [
    CommonModule,
    InvestorsRoutingModule,
    NgxDatatableModule,
    NgxImageGalleryModule,
    SharedModule.forRoot()
  ],
  exports: [InvestorIndividualInfoComponent, InvestorEntityInfoComponent],
  entryComponents: [AddActionItemModalComponent]
})
export class InvestorsModule { }
